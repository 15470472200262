import { gql } from '@apollo/client';
import Middleware from '@/graphql/middlewares/Middleware';
import { activeContentItemMutationsVar, contentItemUpdatedAtVar } from '@/graphql/cache';
import { getISODate } from '@/date';

export default class ContentItemMiddleware extends Middleware {
  /**
   * @param {string} modelType
   * @param {string} modelId
   * @param {boolean} showLoadingIndicator
   */
  constructor(modelType, modelId, showLoadingIndicator = true) {
    super();
    this.modelType = modelType;
    this.modelId = modelId;
    this.showLoadingIndicator = showLoadingIndicator;
  }

  getHeaders(operation) {
    const lockToken = this._getContentItemLockToken(operation.getContext());
    return lockToken ? { 'X-Lock-Token': lockToken } : null;
  }

  beforeRequest(operation) {
    if (this.showLoadingIndicator) {
      const newActiveContentItemMutations = new Map(activeContentItemMutationsVar());

      if (!newActiveContentItemMutations.has(this.modelId)) {
        newActiveContentItemMutations.set(this.modelId, []);
      }

      newActiveContentItemMutations.get(this.modelId).push(operation.operationName);
      activeContentItemMutationsVar(newActiveContentItemMutations);
    }
  }

  afterRequest(operation, result) {
    if (this.showLoadingIndicator) {
      const newActiveMutations = new Map(activeContentItemMutationsVar());
      const contentItemActiveMutations = newActiveMutations.get(this.modelId);

      if (contentItemActiveMutations?.length > 0) {
        newActiveMutations.set(
          this.modelId,
          contentItemActiveMutations.filter((m) => m !== operation.operationName),
        );
        activeContentItemMutationsVar(newActiveMutations);
      }
      this._setContentItemUpdatedAt(result);
    }
  }

  _getContentItemLockToken(context) {
    const contentItem = context.cache.readFragment({
      id: `${this.modelType}:${this.modelId}`,
      fragment: gql`
        fragment ContentItemMiddlewareLock on LockInterface {
          __typename
          id
          lock(lockIfAccessible: true) {
            __typename
            id
            token
          }
        }
      `,
    });

    return contentItem?.lock?.token;
  }

  _setContentItemUpdatedAt(result) {
    if (result.data && !result.errors) {
      const newContentItemUpdatedAt = new Map(contentItemUpdatedAtVar());
      newContentItemUpdatedAt.set(this.modelId, getISODate());
      contentItemUpdatedAtVar(newContentItemUpdatedAt);
    }
  }
}
